/*
CONTENT BLOCK mit einer Spalte Bild oder Video
*/

import * as React from "react";

// CSS
import "../teaser/teaser.css";
import { isMobile } from "react-device-detect";
import VideoPlayer from "../identity/VideoPlayer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductInfoDataEins = ({
     image,
     video,
     videoMobile,
     unterschrift,
     headline,
     text,
     html,
     children
}) => {
    let videoSource = video;
    if (isMobile)
        videoSource = videoMobile;

  return (
    <div className="produkt_content_container">
      {/* Content // add Image or Video */}
      <div>

        {/* Wenn Bild da, dann zeig bild */}
        {image ? (
          <div>
            <img
              className="img_content"
              src={image}
              alt={"Content"}
       
            />
            <span className="bildunterschrift" dangerouslySetInnerHTML={{__html: unterschrift}}/>
          </div>
        ) : (
          <></>
        )} 
        
        {/* Wenn Video da, dann zeig bild */}
        {video ? (
          <div>
              <VideoPlayer controls={false}
                           style={{
                               width: "101%",
                               flex: "101%",
                               border: "1px solid lightgrey"
                           }}
                           trailervideo={videoSource}
                           trailervideoMobile={videoSource}/>
            <span className="bildunterschrift" dangerouslySetInnerHTML={{__html: unterschrift}}/>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* Abstand between     */}
      <div/>

      {/* Content // Add Text */}
      <div>
        <div>
          <p className="bild-headline">{headline}</p>
            {
                html ?
                    (<p className="bild-text" dangerouslySetInnerHTML={{__html: html}}/>)
                :
                    (<p className="bild-text">{text}</p>)
            }
          {children}
        </div>
      </div>
    </div>
  );
};
export default ProductInfoDataEins;

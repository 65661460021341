import * as React from 'react'
import {isMobile} from "react-device-detect";

/***
 * Introvideo auf den Projektunterseiten
 * @param trailervideo Video für Desktop
 * @param trailervideoMobile Video für Mobile
 * @returns {JSX.Element}
 * @constructor
 */
const VideoPlayer = ({trailervideo, trailervideoMobile, controls, style = { width: '100%',  flex: '100%'}}) => {
    let video = (<></>);

    if (isMobile) {
        if (controls === true) {
            video = (<video className="mediacontent"
                            style={style}
                            playsInline
                            muted
                            controls="true"
                            autoPlay="autoplay"
                            loop="true"
                            src={trailervideoMobile} />);
        }
        else {
            video = (<video className="mediacontent"
                            style={style}
                            playsInline
                            muted
                            autoPlay="autoplay"
                            loop="true"
                            src={trailervideoMobile} />);
        }
    }
    else {
        if (controls === true) {
            video = (<video className="mediacontent"
                            style={style}
                            playsInline
                            muted
                            autoPlay="autoplay"
                            controls="true"
                            loop="true"
                            src={trailervideo} />);
        }
        else {
            video = (<video className="mediacontent"
                            style={style}
                            playsInline
                            muted
                            autoPlay="autoplay"
                            loop="true"
                            src={trailervideo} />);
        }
    }


    return (<div className={"intro_unterseite"}>{ video }</div>);
}
export default VideoPlayer

/*
Blickpunkt Moritzburg (CASES)
*/

import * as React from "react";
import { Helmet } from "react-helmet";
import {useTranslation, useI18next} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";

//Components
import Layout from "../../components/layout";
import Abschnitt from "../../components/abschnitt";
import Header from "../../components/header/header";
import ProjectHeader from "../../components/projects/ProjectHeader";
import ContentBlock1S from "../../components/projects/ContentBlock1S";
import ProjectFooter from "../../components/projects/ProjectFooter";
import Reihe from "../../components/generics/reihe";
import SpalteLinks from "../../components/generics/spalte_links";
import SpalteRechts from "../../components/generics/spalte_rechts";
import Trenner from "../../components/trenner";
import FlyingContactClick from "../../components/contactFlyingButton_click.js";
import SEO from "../../components/generics/seo";
import HR from "../../components/generics/HR";

//CSS
import "../../components/teaser/teaser.css";
import "../../components/contactFlyingButton.css";

//DATA
import BlickPunkt_Moritzburg_DENKUNDMACH_web_640 from "../../videos/projects/BlickPunkt.Moritzburg/BlickPunkt.Moritzburg_DENKUNDMACH_web_640.mp4";
import BlickPunkt_Moritzburg_DENKUNDMACH_web_1920 from "../../videos/projects/BlickPunkt.Moritzburg/BlickPunkt.Moritzburg_DENKUNDMACH_web_1920.mp4";

import BlickPunkt_Moritzburg_JamSession_DENKUNDMACH_web_640 from "../../videos/projects/BlickPunkt.Moritzburg/BlickPunkt.Moritzburg_JamSession_DENKUNDMACH_web_640.mp4";
import BlickPunkt_Moritzburg_JamSession_DENKUNDMACH_web_1920 from "../../videos/projects/BlickPunkt.Moritzburg/BlickPunkt.Moritzburg_JamSession_DENKUNDMACH_web_1920.mp4";

import BlickPunkt_Moritzburg_Kunstvermittlung_DENKUNDMACH_web_640 from "../../videos/projects/BlickPunkt.Moritzburg/BlickPunkt.Moritzburg_Kunstvermittlung_DENKUNDMACH_web_640.mp4";
import BlickPunkt_Moritzburg_Kunstvermittlung_DENKUNDMACH_web_1920 from "../../videos/projects/BlickPunkt.Moritzburg/BlickPunkt.Moritzburg_Kunstvermittlung_DENKUNDMACH_web_1920.mp4";

import BlickPunkt_Moritzburg_Plattform_DENKUNDMACH_web_640 from "../../videos/projects/BlickPunkt.Moritzburg/BlickPunkt.Moritzburg_Plattform_DENKUNDMACH_web_640.mp4";
import BlickPunkt_Moritzburg_Plattform_DENKUNDMACH_web_1920 from "../../videos/projects/BlickPunkt.Moritzburg/BlickPunkt.Moritzburg_Plattform_DENKUNDMACH_web_1920.mp4";

import BlickPunkt_Moritzburg_WebGalerie_DENKUNDMACH_web_640 from "../../videos/projects/BlickPunkt.Moritzburg/BlickPunkt.Moritzburg_WebGalerie_DENKUNDMACH_web_640.mp4";
import BlickPunkt_Moritzburg_WebGalerie_DENKUNDMACH_web_1920 from "../../videos/projects/BlickPunkt.Moritzburg/BlickPunkt.Moritzburg_WebGalerie_DENKUNDMACH_web_1920.mp4";

import poster from '../../videos/projects/BlickPunkt.Moritzburg/blickPunkt_poster.jpg'



const helm = {
  title: "DENKUNDMACH - XR-Experience",
  url: "https://denkundmach.com",
  description: "Die interaktive XR-Platform für die Kunstvermittlung des Kunstmuseum Moritzburg Halle",
};

const Product1 = (location) => {
    const { t } = useTranslation("blickpunkt-moritzburg");
    const { language } = useI18next();

    return (
      <>
        {/* Schreibt SEO Optimierungen für die Seite (Titel, Canonical-Link, Beschreibung */}
        {/* LD/JSON bei Bedarf: Product oder ContactPoint von schema.org */}
        <SEO
          lang={language}
          title={t("meta title")}
          canonical={t("meta canonical")}
          description={t("meta description")}
          productLDJSON={true}
        />

        <Layout>
          <Header location={location} />

          <Trenner />

          <Abschnitt>
            {/* Direkt die übersetzten Texte nutzen */}
            {/* Für HTML Inhalte des Langtextes html={} nutzen, ohne Formatierungen das text={} Attribut */}
            {/* Für ein Video muss videoHeader={true} sein, dann die nötigen Attribute*/}
            {/* Für ein Image muss imageHeader={true} sein, dann die nötigen Attribute*/}
            <ProjectHeader
              titel={t("product titel")}
              untertitel={t("product caption")}
              videoHeader={true}
              videoDesktop={BlickPunkt_Moritzburg_DENKUNDMACH_web_1920}
              videoMobile={BlickPunkt_Moritzburg_DENKUNDMACH_web_640}
              videoPoster={poster}
              hashtags={[
                { text: "AR" },
                { text: "education" },
                { text: "multi-user" },
                { text: "participation" },
                { text: "iOS" },
                { text: "android" },
                { text: "BYOD" },
              ]}
              html={t("product description")}
            />
          </Abschnitt>

          {/* Trenner für Abstande oben/unten */}
          <Trenner small={true} />
          {/* HR = Horizontal line, full width */}
          <HR />
          <Trenner small={true} />

          {/* CONTENT***************       */}
          <Abschnitt>
            {/* Info Eins     JAMSESSION  */}
            <ContentBlock1S
              video={BlickPunkt_Moritzburg_JamSession_DENKUNDMACH_web_1920}
              videoMobile={BlickPunkt_Moritzburg_JamSession_DENKUNDMACH_web_640}
              unterschrift={""}
              headline={t("jamsession headline")}
              html={t("jamsession text")}
            />
            <Trenner small={true} />

            {/* Info Zwei     WEBGALERIE  */}
            <ContentBlock1S
              video={BlickPunkt_Moritzburg_WebGalerie_DENKUNDMACH_web_1920}
              videoMobile={BlickPunkt_Moritzburg_WebGalerie_DENKUNDMACH_web_640}
              unterschrift={""}
              headline={t("webgalerie headline")}
              html={t("webgalerie text")}
            />
            <Trenner small={true} />

            {/* Info Drei     PLATTFORM  */}
            <ContentBlock1S
              video={BlickPunkt_Moritzburg_Plattform_DENKUNDMACH_web_1920}
              videoMobile={BlickPunkt_Moritzburg_Plattform_DENKUNDMACH_web_640}
              unterschrift={""}
              headline={t("plattform headline")}
              html={t("plattform text")}
            />
            <Trenner small={true} />

            {/* Info Vier     KUNSTVERMITTLUNG  */}
            <ContentBlock1S
              video={
                BlickPunkt_Moritzburg_Kunstvermittlung_DENKUNDMACH_web_1920
              }
              videoMobile={
                BlickPunkt_Moritzburg_Kunstvermittlung_DENKUNDMACH_web_640
              }
              unterschrift={""}
              headline={t("kunstvermittlung headline")}
              html={t("kunstvermittlung text")}
            />
            <Trenner small={true} />
          </Abschnitt>

          <Trenner small={true} />

          <Abschnitt line={true} headline={t("projektrahmen headline")}>
            {/* ProjectFooter für weitere Infos zum Projekt. text und html werden als erstes angezeigt, dann alle children */}
            {/* Mit <Reihe> und <SpalteLinks> (rechts) wird die ursprüngliche Aufteilung imitiert */}
            <ProjectFooter text={t("projektrahmen text")} html={undefined}>
              <Trenner verysmall={true} />
              <Reihe>
                <SpalteLinks headline={t("projektmanagment")} />
                <SpalteRechts content={t("projektmanagment_content")} />
              </Reihe>

              <Reihe>
                <SpalteLinks headline={t("gestaltung")} />
                <SpalteRechts content={t("gestaltung_content")} />
              </Reihe>

              <Reihe>
                <SpalteLinks headline={t("programmierung")} />
                <SpalteRechts html={t("programmierung_content")} />
              </Reihe>

              <Reihe>
                <SpalteLinks headline={t("server")} />
                <SpalteRechts html={t("server_content")} />
              </Reihe>

              <Reihe>
                <SpalteLinks headline={t("links")} />
                <SpalteRechts html={t("links_content")} />
              </Reihe>


              <Reihe>
                <SpalteLinks headline={t("partner")} />
                <SpalteRechts html={t("partner_content_1")}/> 
              </Reihe>
              <Reihe>
                <SpalteLinks/>
                <SpalteRechts html={t("partner_content_2")}/> 
              </Reihe>
              <Reihe>
                <SpalteLinks/>
                <SpalteRechts html={t("partner_content_3")}/> 
              </Reihe>
              <Reihe>
                <SpalteLinks/>
                <SpalteRechts html={t("partner_content_4")}/> 
              </Reihe>





            </ProjectFooter>
          </Abschnitt>


          <FlyingContactClick />
        </Layout>
      </>
    );
};

export default Product1;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

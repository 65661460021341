import * as React from 'react'

const HR = () => {
    return (
        <section style={{ color: "#232129",
            display: 'flex',
            flexDirection: 'column' }}>
            <div style={{borderTop: "1px solid black", marginBottom:"1em"}}/>
        </section>
    )
}
export default HR
import * as React from "react";

import  "../teaser/teaser.css";
import "../../components/company/team.css";
import VideoPlayer from "../identity/VideoPlayer";
import IntroBase from "../../components/identity/intro_base";

const ProjectHeader = ({
    titel, untertitel,
    hashtags,
    text, html,

    imageHeader,
    imageTeaser, 
    videoHeader,
    videoDesktop,
    videoTablet,
    videoMobile,
    videoPoster,
    videoPosterMobile,
    children,
}) => {
  // Hashtags im loop erzeugen
  let tags = [];
  if(hashtags != null){
    for (let i = -1; ++i < hashtags.length; ) {
      tags.push(
          <>
          <span className="raute"/>
          <span key={i}>{(hashtags[i].t ? hashtags[i].t : hashtags[i].text)}</span>
          <span></span>
          </>
      );
  
  
      // Solange nicht der letzte Link, dann mit Komma trennen
      if (hashtags.length - 1 !== i) {
        tags.push(<span> </span>);
      }
    }
  }

  // Falls Video integriert werden soll
    let video = (<></>)
    if (videoHeader === true) {
        video = (
            <VideoPlayer id="intro-base-mob"
                         trailervideo={videoDesktop}
                         trailervideoTablet={videoTablet}
                         trailervideoMobile={videoMobile}
                         controls={true}
                         poster={videoPoster}
                         postermobile={videoPosterMobile}/>
        )
    }


  // Falls Image integriert werden soll
    let image = (<></>)
    if (imageHeader === true) {

        image = (
          <IntroBase
            image={imageTeaser}
          />
        )
    }



  return (
    <div>
        { video }
        { image }
      <div>
        <h1 className="case_headline">
          {titel}
          {titel ? <span className={"orange"}>.</span> : <></>}
        </h1>
        <span className="untertitel-info">{untertitel}</span>
        <p className="p-tags">{tags}</p>
        {
          html ?
              <p className="p-text" dangerouslySetInnerHTML={{__html: html}}/>
              :
              <p className="p-text">{text} </p>
        }
      </div>

      <div className="produkt_content_container">
      

        <div className="infos">
          { children }
        </div>
      </div>
    </div>
  );
};
export default ProjectHeader;

import * as React from 'react'

const SpalteLinks = ({headline, children}) => {
    return (
        <div className="spalte-links">
        <p className="headline">{headline}</p>
        {children}
        </div>
    )
}
export default SpalteLinks
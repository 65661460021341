import * as React from 'react'
import {Helmet} from "react-helmet";

const SEO = ({ lang, title, canonical, description, productLDJSON, contactLDJSON, children }) => {
    console.log('seo title', title);

    let productLdJson = (<></>);
    if (productLDJSON)
        productLdJson = (
            <script type="application/ld+json">
                {`
                     {
                          "@context": "https://schema.org/",
                          "@type": "Product",
                          "name": "${title}",
                          "image": "",
                          "description": "${description}"
                     }
               `}
            </script>
        );

    let contactLdJson = (<></>);
    if (contactLdJson)
        contactLdJson = (
            <script type="application/ld+json">
                {`
                      {
                          "@context": "https://schema.org",
                          "@type": "Corporation",
                          "name": "DENKUNDMACH.",
                          "url": "https://denkundmach.com",
                          "logo": "",
                          "contactPoint": {
                              "@type": "ContactPoint",
                              "telephone": "+4934520844922",
                              "email": "kontakt@denkundmach.com",
                              "contactType": "customer service"
                          }
                      }
                  `}
            </script>
        );

    return (
        <Helmet htmlAttributes={{
            lang: lang,
        }}>
            <meta charSet="UTF-8" />
            <title>{title}</title>

            <link rel="canonical" href={canonical} />
            <meta name="description" content={description} />

            <meta property="og:locale" content={lang} />
            <meta property="og:title" content={title} />
            <meta property="og:type" content="article" />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={canonical} />
            <meta property="og:site_name" content="denkundmach.com" />

            { productLdJson }
            { contactLdJson }

            { children }
        </Helmet>
    )
}
export default SEO
import * as React from "react";

import  "../teaser/teaser.css";
import "../../components/company/team.css";
import VideoPlayer from "../identity/VideoPlayer";

const ProjectFooter = ({
    text, html,
    children,
}) => {
  return (
    <div>
        {
            html ?
                <p className="p-text" dangerouslySetInnerHTML={{__html: html}}/>
                :
                <p className="p-text">{text} </p>
        }

      <div className="produkt_content_container">
        <div className="infos">
          { children }
        </div>
      </div>
    </div>
  );
};
export default ProjectFooter;

import * as React from 'react'

const SpalteRechts = ({content, children, html}) => {
    return (
        <div className="spalte_rechts">
            {
                html ?
                    (<p className="inhalt" dangerouslySetInnerHTML={{__html: html}}/>)
                :
                    (<p className="inhalt">{content}</p>)
            }
            {children}
            </div>

    )
}
export default SpalteRechts